body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#listContainer {
  width: 25%;
  height: 100vh;
  display: block;
  background-color: white;
  min-width: 320px;
}

#mapContainer {
  width: 75%;
  height: 100vh;
  display: block;
}

.App {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 900px) {
  #listContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 350px;
    z-index: 2;
  }

  #mapContainer {
    position: absolute;
    width: 100vw;
  }

  .App {
    display: block;
  }

  .closed {
    -webkit-transform: translate3d(-300px, 0, 0);
            transform: translate3d(-300px, 0, 0);
  }
  
  .open {
    -webkit-transform: translate3d(0px, 0, 0);
            transform: translate3d(0px, 0, 0);
  }
}

@media only screen and (max-width: 700px) {
  .App {
    display: block;
  }

  #listContainer {
    position: fixed;
    left: 0;
    top: 0;
    width: 300px;
  }

  #mapContainer {
    width: 100vw;
  }

  .closed {
    -webkit-transform: translate3d(-250px, 0, 0);
            transform: translate3d(-250px, 0, 0);
  }
  
  .open {
    -webkit-transform: translate3d(0px, 0, 0);
            transform: translate3d(0px, 0, 0);
  }
}
.menuIcon {
  display: none;
}

@media only screen and (max-width: 900px) {
  .menuIcon {
    display: flex;
    justify-content: flex-end;
  }
}

